import store from '@/store';
import axios from 'axios';

import globalToast from '@/composables/common/globalToast';
import abadiLimitsValidator from '@/composables/kmls/abadiLimitsValidator';

const fileUploader = () => {
    const { showGlobalToast } = globalToast();
    const { validateAbadiLimitNGetInfo } = abadiLimitsValidator();

    const checkNUploadFile = (uploadfilearguments: any, elementsToReset: any) => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        const featureGeometry = ai_currentabadi.abadiFeat.getGeometry();

        const { validAbadiLimit, district, taluk } = <any>validateAbadiLimitNGetInfo(featureGeometry);
        // console.log(validAbadiLimit, district, taluk);

        if(validAbadiLimit){
            uploadFile(uploadfilearguments, elementsToReset);
        } else {
            showGlobalToast('Trying to Upload in Unauthorized Jurisdiction...');
        }
    }

    const uploadFile = (uploadfilearguments: any, elementsToReset: any) => {
        const { fileName, fileExtension, fileCategory, description, fileEl } = uploadfilearguments;
        
        let file = fileEl.files[0];
        // console.log(file);
        
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const ai_currentabadi = store.getters.ai_currentabadi;
        // console.log(ai_currentabadi);

        const { district, taluk, abadilimituuid, abadilimitname } = ai_currentabadi;

        let formData = new FormData();
        formData.append('uploadedfile', file);

        formData.append('currentabadiuuid', abadilimituuid);
        formData.append('abadilimitname', abadilimitname);

        formData.append('currentdistrict', district);
        formData.append('currenttaluk', taluk);

        formData.append('fileName', fileName);
        formData.append('fileExtension', fileExtension);
        formData.append('filecategory', fileCategory);
        formData.append('description', description);
        formData.append('currentuser', username);
        console.log(...formData);

        const fileServerURL = store.getters.g_httpfilePostURL;
        const uploadURL = fileServerURL + `/fileupload?validateusername=${username}&validatepassword=${password}`;
        // console.log(uploadURL);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (p: any) => {
                let percentCompleted = Math.round((p.loaded * 100) / p.total)
                store.dispatch('af_fileUploadProgress', percentCompleted);
            }
        };

        axios.post(uploadURL, formData, config)
            .then((response) => {
                if(response.data == 'success'){
                    showGlobalToast('File Uploaded Successfully');

                    resetElements(elementsToReset);
                } else {
                    showGlobalToast('Error Uploading File');
                }
            }).catch((error) => {
                // console.log(error);
                showGlobalToast('Error Uploading File');
            }).finally(() => {
                store.dispatch('af_uploadbtndisabled', false);
            });
    }

    const resetElements = (els: any) => {
        els.forEach((el: any) => el.value = '');
        store.dispatch('af_fileUploadProgress', 0);
    }

    return { checkNUploadFile, uploadFile };
}

export default fileUploader;