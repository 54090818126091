
import { computed, defineComponent, onMounted, ref } from 'vue';
import './AbadiFilesUpload.scss';

import AbadisDownload from '@/views/AbadisDownload.vue';
import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import fileUploader from '@/composables/abadifiles/fileUploader';

export default defineComponent({
    components: {
        AbadisDownload
    },
    setup() {
        const { showGlobalToast } = globalToast();
        const { checkNUploadFile } = fileUploader();

        const fileCategoriesArry = ['Hand Sketch', 'Draft Map PDF', 'Correction PDF', 'Others'];

        const abadiObjAvailInStore = computed(() => 'abadilimituuid' in store.getters.ai_currentabadi);
        
        const fileCategory = ref('');
        const description = ref('');

        const fileEl = ref();
        const fileName = ref('');
        const fileExtension = ref('');
        const af_uploadbtndisabled = computed(() => store.getters.af_uploadbtndisabled);
        const af_fileUploadProgress = computed(() => store.getters.af_fileUploadProgress);

        onMounted(() => {
            fileEl.value.addEventListener('change', loadFileInformation);
        });

        const loadFileInformation = (e: any) => {
            let file = e.target.files[0];
            let fileFullname = file.name;
            let lastDot = fileFullname.lastIndexOf('.');
            let extension = fileFullname.substring(lastDot + 1);
            let filename = fileFullname.substring(0, lastDot);
            fileName.value = filename;
            fileExtension.value = extension;
        }

        const calluploadfile = () => {
            if(fileCategory.value == ''){
                showGlobalToast('Select File Category');
                return 0;
            }

            if(fileName.value == '' || fileExtension.value == ''){
                showGlobalToast('Select Valid File');
                return 0;
            }

            if(description.value == ''){
                showGlobalToast('Enter Some Description');
                return 0;
            }
            
            let uploadfilearguments = {
                fileName: fileName.value,
                fileExtension: fileExtension.value,
                fileCategory: fileCategory.value,
                description: description.value,
                fileEl: fileEl.value
            };

            let elementsToReset = [
                fileEl.value, fileCategory, description, fileName, fileExtension
            ];

            checkNUploadFile(uploadfilearguments, elementsToReset);
            store.dispatch('af_uploadbtndisabled', true);
        }

        return {
            fileCategoriesArry, abadiObjAvailInStore, fileName, fileExtension,
            fileCategory, description, fileEl, af_uploadbtndisabled, af_fileUploadProgress,
            calluploadfile
        }
    },
})
